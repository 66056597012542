<template>
	<footer v-if="common" v-cloak>
		<div class="footerTop widthBox acea-row row-between">
			<div class="item hidden-xs-only" v-for="item in common.bottom_nav">
				<router-link class="title" :to="item.href||'/'">{{item.name}}</router-link>
				<div class="xiang"></div>
				<div class="detail">
					<router-link class="name" :to="it.href||'/'" v-for="it in item.son">{{it.name}}</router-link>
				</div>
			</div>
			<div class="item hidden-xs-only">
				<div class="title">{{lang.public.text19}}</div>
				<div class="xiang"></div>
				<div class="detail" v-if="common.contact_us">
					<img class="code" v-for="item in common.contact_us.ewm" :src="item" alt="">
				</div>
			</div>
			
			<div class="footerNav hidden-sm-and-up">
				<router-link v-for="item in common.bottom_nav" class="title" :to="item.href||'/'">{{item.name}}</router-link>
			</div>
			<div class="codeBox hidden-sm-and-up" v-if="common.contact_us">
				<img class="code" v-for="item in common.contact_us.ewm" :src="item" alt="">
			</div>
		</div>
		<div class="copy" v-if="common.contact_us" v-cloak>
			<div class="widthBox">
				<span>{{common.contact_us.copyright}}</span><span style="margin-left:50px;">{{common.contact_us.icp}}</span><br/>
				<span>{{lang.about.text04}}：{{common.contact_us.address}}</span><span style="margin-left:150px;">{{lang.about.text01}}：{{common.contact_us.phone}}</span>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'About',
		components:{
		},
		computed: {
			common() {
				return this.$store.state.common
			},
			lang() {
				return this.$store.state.lang
			},
		},
		}
</script>

<style lang="scss" scoped="scoped">
	footer{
		margin-top:80px;
		background:#1D2A55;
		color:#FFF;
		.footerTop{
			padding:80px 0 0 0;
			.item{
				width:180px;
				.title{
					font-size:24px;
					color:#FFF;
				}
				
				.xiang{
					background:rgba($color: #CCCCCC, $alpha: 0.2);
					height:2px;
					position: relative;
					margin-top:20px;
					&::after{
						content:'';
						display: block;
						width:60px;height:4px;
						background:#FFF;
						position: absolute;
						left:0;
						top:-1px;
						z-index: 1;
					}
				}
				.detail{
					padding:40px 0;
					font-size:18px;
					line-height:48px;
					.name{
						color:#FFF;
						display: block;
						line-height:48px;
						font-size:18px;
					}
					.code{
						width:124px;
						margin:0 10px 10px 0;
					}
				}
			}
		}
		.copy{
			border-top:1px solid #FFF;
			padding:20px 0;
			font-size:16px;
			line-height:36px;
		}
	}
	
	@media screen and(max-width:1400px) {
		footer{
			margin-top:70px;
			.footerTop{
				padding:60px 0 0 0;
				.item{
					width:150px;
					.title{
						font-size:20px;
						color:#FFF;
					}
					
					.xiang{
						margin-top:20px;
						&::after{
							width:50px;height:4px;
						}
					}
					.detail{
						padding:30px 0;
						font-size:16px;
						line-height:40px;
						.name{
							color:#FFF;
							display: block;
							line-height:40px;
							font-size:16px;
						}
						.code{
							width:110px;
						}
					}
				}
			}
		}
	}
	@media screen and(max-width:1200px) {
		footer{
			margin-top:70px;
			.footerTop{
				padding:60px 0 0 0;
				.item{
					width:120px;
					.title{
						font-size:18px;
					}
					
					.xiang{
						margin-top:15px;
						&::after{
							width:50px;height:4px;
						}
					}
					.detail{
						padding:20px 0;
						font-size:14px;
						line-height:36px;
						.name{
							color:#FFF;
							line-height:36px;
							font-size:14px;
						}
					}
				}
			}
		}
	} 
	@media screen and(max-width:768px) {
		footer{
			margin-top:40px;
			.footerTop{
				padding:20px;
				.footerNav{
					.title{
						width:25%;
						font-size:14px;
						text-align: center;
						color:#FFF;
						display: inline-block;
						line-height:44px;
					}
				}
				.codeBox{
					.code{width:80px;margin:0 10px;}
				}
			}
			.copy{
				line-height:0;
				border-color:#494949;
				span{
					display: block;
					line-height:24px;
					margin-left:0 !important;
					color:#BCBCBC;
				}
			}
		}
	}
</style>
