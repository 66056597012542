<template>
	<div id="floatBox">
		<ul>
			<li @click="lyChange">
				<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
				<p>{{lang.public.text01}}</p>
			</li>
			<li @click="kfChange">
				<span class="iconfont icon-kefu acea-row row-center-wrapper"></span>
				<p>{{lang.public.text11}}</p>
			</li>
			<li @click="topBtn">
				<span class="iconfont icon-xiangshang acea-row row-center-wrapper"></span>
				<p>{{lang.public.text12}}</p>
			</li>
		</ul>
		<div>
			<div v-if="kfShow" class="kfBox">
				<div class="kfItem" v-for="item in kfList">
					<img :src="item.kf_img" alt="" class="pic">
					<p>{{item.kf_name}}</p>
					<p>{{lang.public.text13}}：{{item.kf_phone}}</p>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		computed: {
			kfShow() {
				return this.$store.state.kfShow
			},
			lang() {
				return this.$store.state.lang
			},
		},
		data() {
			return {
				width:0,
				height:0,
				kfList:[],
			}
		},
		created() {
			this.$api.get('home/index/kefu',{
			}).then(res=>{
				this.kfList = res.data.list
			})
		},
		mounted() {
		},
		methods: {
			topBtn(){
				document.body.scrollIntoView()
			},
			lyChange(){
				this.$emit('messageShow',true)
			},
			kfChange(){
				this.$store.state.kfShow = true
				this.$emit('getBgShow',true)
			}
		}
	}
</script>

<style lang="scss">
	#floatBox{
		position: fixed;
		top:50%;
		right:50px;
		z-index: 100;
		transform: translate(0,-50%);
		li{
			text-align: center;
			cursor: pointer;
			margin-bottom:20px;
			p{
				font-size:14px;
				color:#1D2A55;
				line-height:24px;
			}
			.iconfont{
				background:#1D2A55;
				width:60px;
				height:60px;
				font-size:32px;
				color:#FFF;
				border-radius:10px;
			}
			&:hover{
				.iconfont{
					background:#FFF;
					color:#1D2A55;
					box-shadow: 0px 0px 10px 1px rgba(29, 42, 85, 0.2);
				}
			}
		}
		.kfBox{
			position:absolute;
			right:80px;
			top:0;
			width:240px;
			border-radius:10px;
			box-sizing: border-box;
			background:#FFF;
			text-align: center;
			padding:20px 0;
			.pic{
				width:120px;
			}
		}
	}
</style>
