<template>
	<div class="swiperBox">
		<div class="swiper-bannerA" :class="scale?'scale banner_'+jsClass:'banner_'+jsClass">
			<div class="swiper-wrapper">
				<div class="swiper-slide"  v-for="(item,index) in list">
					<template v-if="item.video">
						<video ref="video" :autoplay="false" :poster="item.back_img" controls>
							<source :src="item.video" type="video/mp4">
							<source :src="item.video" type="video/ogg">
						</video>
					</template>
					<template v-if="itemType == 'honor'">
						<img :src="item.img" alt="">
						<div style="margin-top:10px;" class="name">{{item.title}}</div>
					</template>
					<template v-if="itemType == 'team'">
						<img :src="item.team_img" alt="">
						<h4 style="margin-top:10px;">{{item.team_name}}</h4>
						<div class="name"><template v-for="i in item.position_title">{{i}}</template></div>
					</template>
					
				</div>
			</div>
			<div class="swiper-pagination" :class="jsClass"></div>
			<div class="swiper-scrollbar" :class="jsClass"></div>
		</div>
		<template v-if="itemType == 'team'">
			<div v-if="navigation" :class="jsClass" class="list_btn swiper-button-prev acea-row row-center-wrapper">
				<span class="el-icon-arrow-left"></span>
			</div>
			<div v-if="navigation" :class="jsClass" class="list_btn swiper-button-next acea-row row-center-wrapper">
				<span class="el-icon-arrow-right"></span>
			</div>
		</template>
		<template v-else>
			<div v-if="navigation" :class="jsClass" class="swiper-btn swiper-button-prev acea-row row-center-wrapper">
				<div class="btn">上一个</div>
			</div>
			<div v-if="navigation" :class="jsClass" class="swiper-btn swiper-button-next acea-row row-center-wrapper">
				<div class="btn">下一个</div>
			</div>
		</template>
	</div>
	
</template>
<script>
	import Swiper,{Navigation,Pagination,Autoplay} from 'swiper';
	export default {
		props: {
			list:{//列表
				type:Array()
			},
			autoPlay:{
				type:Boolean,
				default:true
			},
			type:{//轮播类型
				type:String
			},
			itemType:{//内容展示类型
				type:String
				
			},
			navigation: {//是否需要左右按钮
				type: Boolean,
				default: true
			},
			padding:{
				type:Number,
				default:20
			},
			jsClass:{
				type:String,
				default:'js_bannerA'
			},
			scale:{//是否放大
				type:Boolean,
				default:true,
			},
			slides:{//展示数量
				type:Number,
				default:3
			}
		},
		mounted() {
			console.log(this.scale)
			this.$nextTick(()=>{
				this.openSwiper()
			})
			
		},
		methods: {
			openSwiper() {
				setTimeout(()=>{
					var that = this
					var swiper = new Swiper('.banner_'+this.jsClass, {
						speed:300,
						autoplay:this.autoPlay?{delay:3000}:false,
						slidesPerView: this.slides,
						slidesPerGroup: 1,
						centeredSlidesBounds: this.scale,
						centeredSlides: this.scale,
						spaceBetween: this.padding,
						loop: true,
						observer:true,
						observeParents:true,
						centerInsufficientSlides: true,
						modules: [Navigation, Pagination, Autoplay],
						pagination: {
							el: '.swiper-pagination.'+this.jsClass,
							type: 'bullets',
							bulletClass: 'span-look',
							clickable: true,
						},
						navigation: {
							nextEl: '.swiper-button-next.'+this.jsClass,
							prevEl: '.swiper-button-prev.'+this.jsClass,
							hideOnClick: true
						},
						on: {
							slideChangeTransitionEnd(e){
								that.slideIndex = e.activeIndex
								that.list.map((item,index)=>{
									if(item.video){
										that.$refs.video[index].pause()
									}
								})
								that.$emit('rotationIndex',e.activeIndex)
							}
						},
					})
				},500)
			}
		}
	}
</script>

<style lang="scss">
	.swiperBox{
		position: relative;
	}
	.swiper-bannerA{
		overflow: hidden;
		&.scale{
			padding-bottom:60px;
			.swiper-slide-active {
				z-index: 2;
				transform: scale(1.2);
			}
			.swiper-pagination{
				bottom:-90px;
			}
			.swiper-slide{
				margin-top:30px;
			}
		}
		.swiper-slide {
			z-index: 1;
			margin-top: 0px;
			transition: 0.5s;
			transform: scale(1);
		}
		h4{
			text-align: center;
			font-size:18px;
			line-height:32px;
		}
		.name{
			text-align: center;
			font-size:18px;
			line-height:36px;
		}
		img {
			width: 100%;
		}
		video{
			width:100%;
			height:300px;
			background:#000;
		}

		

		
	}
	.list_btn{
		position: absolute;
		top:50%;
		width:48px;
		height:48px;
		border-radius:50%;
		z-index: 2;
		color:#FFF;
		border:1px solid #FFF;
		cursor: pointer;
		span{
			font-size:24px;
			font-weight: bold;
		}
		&.swiper-button-prev{
			left:50px;
		}
		&.swiper-button-next{
			right:50px;
		}
		img{
			width:14px;
		}
	}
	.swiper-btn {
		position: absolute;
		top: calc(50% + 15px);
		transform: translate(0, -50%);
		width: 50px;
		height: 65px;
		background: rgba($color: #1D2A55, $alpha: 1.0);
		color: #FFF;
		box-sizing: border-box;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		z-index: 3;
		cursor: pointer;
	
		.btn {
			position: relative;
		}
	
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
	
			border: {
				top: 10px solid transparent;
				right: 10px solid #FFF;
				bottom: 10px solid transparent;
				left: 10px solid transparent;
	
			}
		}
	
		&.swiper-button-prev {
			left: 0px;
			padding-left: 30px;
	
			&::after {
				left: 0px;
			}
		}
	
		&.swiper-button-next {
			right: 0px;
			padding-left: 10px;
			padding-right: 20px;
	
			&::after {
				right: 0px;
	
				border: {
					right: 10px solid transparent;
					left: 10px solid #FFF;
	
				}
			}
		}
	}
	.swiper-pagination {
		position: absolute;
		bottom: -60px;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 2;
	
		.span-look {
			width: 16px;
			height: 16px;
			border-radius: 8px;
			background: rgba($color: #1D2A55, $alpha:0.5);
			display: inline-block;
			margin: 0 4px;
	
			&.swiper-pagination-bullet-active {
				width: 50px;
				background: #1D2A55;
			}
		}
	}
	
	@media screen and(max-width:1400px) {
		.swiper-bannerA{
			h4{font-size:16px;}
			.name{font-size:16px;}
		}
	}
	
	@media screen and(max-width:768px) {
		.swiper-bannerA{
			overflow: hidden;
			&.scale{
				padding-bottom:0px;
				.swiper-slide-active {
					z-index: 2;
					transform: scale(1.2);
				}
				.swiper-pagination{
					bottom:-40px;
				}
				.swiper-slide{
					margin-top:30px;
				}
			}
			.swiper-slide {
				z-index: 1;
				margin-top: 0px;
				transition: 0.5s;
				transform: scale(1);
			}
			video{
				width:100%;
				height:300px;
				background:#000;
			}
			.name{
				line-height:24px;
				height:72px;
			}
		}
	
		.swiper-pagination {
			bottom: 0px;
			&.case_banner{
				bottom:-30px;
			}
			.span-look {
				width: 12px;
				height: 12px;
		
				&.swiper-pagination-bullet-active {
					width: 12px;
				}
			}
		}
	}
</style>
