<template>
  <div id="app">
	<topHeader></topHeader>
    <router-view/>
	<footerView></footerView>
  </div>
</template>
<script>
import topHeader from '@/components/header.vue'
import footerView from '@/components/footer.vue'
export default {
  name: 'Home',
  components: {
    topHeader,footerView
  },
  created() {
	this.$api.get('home/index/commonIndex',{}).then(res=>{
		this.$store.state.bannerS = res.data.slide_item
		this.$store.state.common = res.data
	})
  }
}
</script>
<style lang="scss">
	body,html{
		box-sizing: border-box;
		overflow-x:hidden;
	}
	.widthBox{
		width:1400px;
		margin:0 auto;
	}
	html,body,div,span,p,i,a,h1,h2,h3,h4,h5,h6{
		margin:0;
		padding:0;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
		font-size:12px;
	}
	ul,li{
		list-style: none;
		margin:0;padding:0;
	}
	a{
		color:#666;
		text-decoration: none;
	}
	
	
	.acea-row{display:flex;flex-wrap:wrap;
		&.row-top{align-items:flex-start;}
		&.row-middle{align-items:center;}
		&.row-bottom{align-items:flex-end;}
		&.row-left{justify-content:flex-start;}
		&.row-center{justify-content:center;}
		&.row-right{justify-content:flex-end;}
		&.row-between{justify-content:space-between;}
		&.row-around{justify-content:space-around;}
		&.row-column{flex-direction:column;}
		&.row-column-between{flex-direction:column;justify-content:space-between;}
		&.row-column-around{flex-direction:column;justify-content:space-around;}
		&.row-center-wrapper{align-items:center;justify-content:center;}
		&.row-between-wrapper{align-items:center;justify-content:space-between;}
		&.row-around-wrapper{align-items:center;justify-content:space-around;}
	}
	@media screen and(max-width:1400px) {
		.widthBox{
			width:1200px !important;
		}
	}
	@media screen and(max-width:1200px) {
		.widthBox{
			width:1000px !important;
		}
	}
	@media screen and(max-width:768px) {
		.widthBox{
			width:100% !important;
			padding:0 20px;
			box-sizing: border-box;
		}
	}
</style>
