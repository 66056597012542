import Vue from 'vue'
import Vuex from 'vuex'
import lang from '@/utils/lang.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  bannerS:[],
	  common:{},
	  kfShow:false,
	  lang:lang
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
