<template>
	<div class="header acea-row row-center-wrapper">
		<div class="widthBox acea-row row-between-wrapper">
			<router-link class="hidden-sm-and-up" to="/"><img class="logo" src="@/assets/logo_02.png" alt=""></router-link>
			<router-link class="hidden-xs-only" to="/"><img class="logo" src="@/assets/logo.png" alt=""></router-link>
			
			<div id="nav" class="hidden-xs-only">
			  <router-link v-for="item in navS" :to="item.url">{{item.name}}</router-link>
			</div>
			<img @click="mobileShow = !mobileShow" class="icon hidden-sm-and-up" src="@/assets/icon_05.png" alt="">
		</div>
		<div v-if="mobileShow" @click="mobileShowChange" data-type="hidden" class="navBg hidden-sm-and-up">
			<div id="navM">
				<router-link data-type="hidden" v-for="item in navS" :to="item.url">{{item.name}}</router-link>
			</div>
		</div>
		
		<floatBox class="hidden-xs-only" @messageShow="messageType" @getBgShow="getShow"/>
		<div @click="hideBg"  v-if="bgShow" class="fixedBg"></div>
		<div v-if="messageShow" class="messageBox">
			<h4>{{lang.public.text01}}</h4>
			<div class="cont">
				<div class="formInput">
					<input v-model="formData.name" :placeholder="lang.public.text02" type="text" />
					<input v-model="formData.mobile" :placeholder="lang.public.text03" type="text" />
					<input v-model="formData.city" :placeholder="lang.public.text04" type="text" />
				</div>
				<textarea v-model="formData.content" rows="5" :placeholder="lang.public.text05"></textarea>
				<button @click="messageSubmit">{{lang.public.submit}}</button>
			</div>
		</div>
	</div>
</template>

<script>
	import floatBox from '@/components/floatBox.vue'
	export default {
		components:{
			floatBox
		},
		computed: {
			lang() {
				return this.$store.state.lang
			},
		},
		data() {
			return {
				bgShow:false,
				messageShow:false,
				navS:[
					{name:this.$store.state.lang.menu.index,url:'/'},
					{name:this.$store.state.lang.menu.about,url:'/about'},
					{name:this.$store.state.lang.menu.product,url:'/product'},
					{name:this.$store.state.lang.menu.case,url:'/case'},
					{name:this.$store.state.lang.menu.business,url:'/business'},
					{name:this.$store.state.lang.menu.news,url:'/news'},
					{name:this.$store.state.lang.menu.join,url:'/join'},
					{name:this.$store.state.lang.menu.recruit,url:'/recruit'},
				],
				formData:{
					name:'',
					mobile:'',
					city:'',
					content:''
				},
				mobileShow:false
			}
		},
		mounted() {
		},
		created() {
		},
		methods: {
			mobileShowChange(e){
				var type = e.target.dataset.type
				if(type == 'hidden'){
					this.mobileShow=false
				}
			},
			getShow(e){
				this.bgShow = e
			},
			messageType(e){
				this.messageShow = true
				this.bgShow = true
			},
			messageSubmit(){
				let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
				let lang = this.lang.public
				if(!this.formData.name||!this.formData.mobile||!this.formData.city||!this.formData.content){
					this.$message.error(lang.text06);
					return
				}
				if(!reg.test(this.formData.mobile)){
					this.$message.error(lang.text07);
					return
				}
				this.$confirm(lang.text08, lang.text09, {
				  confirmButtonText: lang.text10,
				  cancelButtonText: lang.close,
				  type: 'info'
				}).then(() => {
				  this.$api.post('/home/submits/contactUs',this.formData).then(res=>{
					  this.$message({
						message:res.msg,
						type: 'success'
					  });
					  this.messageShow = false
					  this.bgShow = false
					  this.$store.state.kfShow = false
				  })
				})
			},
			hideBg(){
				this.bgShow = false
				this.messageShow = false
				this.$store.state.kfShow = false
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.header{
		height:100px;
		border-bottom:1px solid #1D2A55;
		.messageBox{
			position: fixed;
			left:50%;
			top:50%;
			background:#FFF;
			transform: translate(-50%,-50%);
			z-index: 22;
			width:60%;
			min-width:400px;
			box-sizing: border-box;
			padding:20px 40px;
			.cont{text-align: center;}
			h4{
				color:#1D2A55;
				font-size:30px;
				text-align: center;
			}
			.formInput{
				margin-top:40px;
				input{
					background:rgba($color: #1D2A55, $alpha: 0.1);
					height:48px;
					width:calc(100% / 3 - 20px);
					border:0;
					margin-right:30px;
					box-sizing: border-box;
					padding:0 20px;
					&:last-child{
						margin-right:0;
					}
				}
			}
			textarea{
				margin-top:20px;
				background:rgba($color: #1D2A55, $alpha: 0.1);
				border:0;
				width:100%;
				box-sizing: border-box;
				padding:20px;
			}
			button{
				width: 50%;
				max-width:300px;
				height: 48px;
				background: #1D2A55;
				color:#FFF;
				font-size:18px;
				margin:30px auto 0;
				border-radius: 2px;
			}
		}
		.fixedBg{
			width:100%;height:100%;
			background:rgba($color: #000000, $alpha: 0.4);
			position:fixed;
			left:0;
			top:0;
			z-index: 20;
		}
		.logo{
			width:160px;
		}
		#nav{
			a{
				font-size:20px;
				color:#1D2A55;
				margin-left:60px;
				display: inline-block;
				line-height:64px;
				height:64px;
				position:relative;
				&.router-link-exact-active{
					&::after{
						content:'';
						display: block;
						width:100%;
						height:6px;
						border-radius:4px;
						position: absolute;
						left:0;
						bottom:0;
						background:#1D2A55;
					}
				}
				
			}
		}
	}
	
	@media screen and(max-width:1400px) {
		.header{
			#nav{
				a{
					margin-left:50px;
					font-size:18px;
				}
			}
		}
	}
	@media screen and(max-width:1200px) {
		.header{
			#nav{
				a{
					margin-left:30px;
					font-size:16px;
				}
			}
		}
	} 
	@media screen and(max-width:768px) {
		.header{
			height:60px;
			.widthBox{
				position: fixed;
				left:0;
				top:0;z-index: 100;
				background:#FFF;
				height:60px;
				box-sizing: border-box;
				padding:0 20px;
			}
			.logo{
				width:auto;
				height:30px;
			}
			.icon{
				width:24px;
			}
			#navM{
				background:#1D2A55;
				height:100%;
				width:60%;
				a{
					display: block;
					color:#FFF;
					border-bottom:1px solid #FFF;
					font-size:14px;
					line-height:48px;
					padding:0 10px;
				}
			}
			.navBg{
				position: fixed;
				left:0;
				top:60px;
				z-index: 100;
				background:rgba($color: #000000, $alpha: 0.4);
				width:100%;
				height:calc(100% - 60px);
			}
		}
	} 
</style>
